import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/home', title: 'Home',  icon: 'home', class: '' },
    { path: '/schedule', title: 'Events',  icon:'date_range', class: '' },
    { path: '/sponsors', title: 'Sponsors', icon: 'attach_money', class: '' },
    { path: '/social-media', title: 'Social Media', icon: 'favorite', class: '' },
    { path: '/roster', title: 'Roster', icon: 'content_paste', class: '' },
    //{ path: '/events', title: 'Events', icon: 'event', class: ''},
    //{ path: '/youth-clinic', title: 'Youth Clinic', icon: 'bubble_chart', class: '' },
    { path: '/donate', title: 'Donate', icon: 'credit_card', class: ''},
    { path: '/maps', title: 'Maps',  icon:'near_me', class: '' },
    { path: '/contacts', title: 'Contacts', icon: 'account_box', class: '' },
    //{ path: '/admin', title: 'Admin', icon: 'accessibility', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
