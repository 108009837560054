import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { ScheduleComponent } from './schedule/schedule.component';
import {  } from './schedule/game/game.component';
import { RosterComponent } from './roster/roster.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AgmCoreModule } from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { EventsComponent } from './events/events.component'
import { SocialMediaComponent } from './social-media/social-media.component'
import { AdminComponent } from './admin/admin.component'
import { DonateComponent } from './donate/donate.component'
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import {  JsonpModule } from '@angular/http';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
        }),
        HttpClientModule, 
        JsonpModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,

    ],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
    bootstrap: [AppComponent]
})
export class AppModule { }
